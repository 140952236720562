import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Stepper,
  TextField,
  Autocomplete,
  Step,
  StepLabel,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Button,
  Grid,
  Alert,
  FormControlLabel,
  Checkbox,
  AlertTitle,
  Snackbar,
  Chip,
  Divider,
  ImageList,
  ImageListItem,
  IconButton,
  ImageListItemBar,
  Modal,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NumericFormat } from "react-number-format";

import GetAppIcon from "@mui/icons-material/GetApp";
import "../theme.js"; // Import the global CSS here

import CheckIcon from "@mui/icons-material/Check";
import PendingIcon from "@mui/icons-material/Pending";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InsuranceIcon from "@mui/icons-material/HealthAndSafety";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachFile from "@mui/icons-material/AttachFile";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import Header from "../components/Header";
import Sidebar from "../components/SideBar";
import Notification from "../components/Notification";
import DossierSkeleton from "../components/DossierSkeleton";

import { fetchUserDetails } from "../services/LoginService";
import { getDossierById } from "../services/DossierService";
import {
  DossierUpdate,
  TriggerPdfGeneration,
} from "../services/DossierUpdateService";
import { getUserData } from "../services/UserTableService";
import { UpdateFolder } from "../services/UpdateFolderService";
import uploadFileService from "../services/UploadFileService";
import { deleteDossierService } from "../services/DeleteDossierService.js";
import {
  AddCircle,
  AutoMode,
  Check,
  Delete,
  Download,
  DownloadDone,
  Edit,
  LockOpen,
  RemoveCircle,
  SkipNext,
  SkipPrevious,
  Upload,
  Visibility,
} from "@mui/icons-material";
import ActionsButton from "../components/ActionsButton.js";
import UploadDropzone from "../components/UploadDropzone.js";

const DossierPage = () => {
  const [dossier, setDossier] = useState(null);
  const { id } = useParams();
  const [selectedGarageId, setSelectedGarageId] = useState(null);
  const [garages, setGarages] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showGarageModal, setGarageShowModal] = useState(false);
  const [showGarageValidationModal, setShowGarageValidationModal] =
    useState(false);
  const [showEditeModal, setEditeShowModal] = useState(false);
  const [addShowModal, setAddShowModal] = useState(false);
  const [rendezVousModal, setRendezVousModal] = useState(false);
  const [uploadFacturationModel, setUploadFacturationModel] = useState(false);
  const [UserDetails, setUserDetails] = useState(null);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState("success");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const [showFactureModal,setShowFactureModal] = useState(false);

  const baseUrl = process.env.REACT_APP_BACK_BASE_URL;

  /// DATA LIST ///
  const [fields, setFields] = useState([{ key: '', prix: 0, quantity: 0}]);

  const [formData, setFormData] = useState({
    name_or_company: "",
    first_name: "",
    email: "",
    tel: "",
    city: "",
    address: "",
    postal_code: "",
    license_plate: "",
    vehicle_model: "",
    insurance_name: "",
    insurance_policy_number: "",
    date_incident: "",
    date_declaration: "",
    incident_reason: "",
    glass_type: "",
    gift_type: "",
    glass_photo: "",
    green_card_photo: "",
    vehicle_registration_photo: "",
    incident_number: "",
    vehicle_mileage: "",
    installation_address: "",
    internal_reference: "",
    professional: false,
    insurance_comment: "",
    insured_client: false,
    verified_deductible: false,
    sent_insurance: false,
    sent_invoice_to_insurance: false,
    payment_received: false,
    deductible_amount: "",
    mine_type: "",
    serial_number: "",
    appointment_date: "",
    additional_fields: "",
    cession_creance: "",
    facture: "",
  });

  /// USER DETAILS ///
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const data = await fetchUserDetails();
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    getUserDetails();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDossierById(id);
        setDossier(data);

        let date_declaration = new Date(data.date_declaration);
        let date_incident = new Date(data.date_incident);
        let appointment_date = new Date(data.appointment_date);

        let formatted_date_declaration = date_declaration
          .toISOString()
          .split("T")[0];
        let formatted_date_incident = date_incident.toISOString().split("T")[0];
        let formatted_appointment_date = appointment_date
          .toISOString()
          .split("T")[0];

        setFormData({
          name_or_company: data.name_or_company || "",
          first_name: data.first_name || "",
          email: data.email || "",
          tel: data.tel || "",
          city: data.city || "",
          address: data.address || "",
          postal_code: data.postal_code || "",
          license_plate: data.license_plate || "",
          vehicle_model: data.vehicle_model || "",
          insurance_name: data.insurance_name || "",
          insurance_policy_number: data.insurance_policy_number || "",
          date_incident: formatted_date_incident || "",
          date_declaration: formatted_date_declaration || "",
          incident_reason: data.incident_reason || "",
          glass_type: data.glass_type || "",
          gift_type: data.gift_type || "",
          glass_photo: data.glass_photo || "",
          green_card_photo: data.green_card_photo || "",
          vehicle_registration_photo: data.vehicle_registration_photo || "",
          incident_number: data.incident_number || "",
          vehicle_mileage: data.vehicle_mileage || "",
          installation_address: data.installation_address || "",
          internal_reference: data.internal_reference || "",
          professional: Boolean(data.professional),
          insurance_comment: data.insurance_comment || "",
          insured_client: Boolean(data.insured_client),
          verified_deductible: Boolean(data.verified_deductible),
          sent_insurance: Boolean(data.sent_insurance),
          sent_invoice_to_insurance: Boolean(data.sent_invoice_to_insurance),
          payment_received: Boolean(data.payment_received),
          deductible_amount: data.deductible_amount || "",
          mine_type: data.mine_type || "",
          serial_number: data.serial_number || "",
          appointment_date: formatted_appointment_date || "",
          additional_fields: data.additional_fields || "",
          cession_creance: data.cession_creance || "",
          facture: data.facture || "",
        });
      } catch (error) {
        console.error("Error fetching dossier data:", error);
      }
    };

    fetchData();
  }, [id]);
  useEffect(() => {
    if (dossier) {
      const formattedFields = Object.entries(dossier.additional_fields || {}).map(([key, value]) => ({
        key,
        prix: value.prix || 0, // Ensure default for `prix`
        quantity: value.quantity || 0, // Ensure default for `quantity`
      }));
      setFields(formattedFields.length ? formattedFields : [{ key: '', prix: 0, quantity: 0}]);
    }
  },[dossier]);
  const { userid, usertype } = UserDetails || {};
  ///  SKELETON ///
  if (!dossier) {
    return <DossierSkeleton />;
  }
  // function to view pdf
  const ViewPdf = async () => {
    const res = await TriggerPdfGeneration(
      dossier.dossier_id,
      dossier.garage_id
    );
    const url = `${process.env.REACT_APP_BACK_BASE_URL}/${res.data.cession_creance}`;
    window.open(url, "_blank");
  };
  const ViewPdfFacture = async () => {
    const url = `${process.env.REACT_APP_BACK_BASE_URL}/${dossier.facture}`;
    window.open(url, "_blank");
  };
  // function to download pdf
  const DownloadPdfCCA = async () => {
    const res = await TriggerPdfGeneration(
      dossier.dossier_id,
      dossier.garage_id
    );
    const url = `${process.env.REACT_APP_BACK_BASE_URL}/${res.data.cession_creance}`;
    // Trigger download by creating a hidden link and clicking it programmatically
    await handleDownload(url, `Cession-creance-${dossier.dossier_id}.pdf`);
  };
  const DownloadPdfFacture = async () => {
    const url = `${process.env.REACT_APP_BACK_BASE_URL}/${dossier.facture}`;
    // Trigger download by creating a hidden link and clicking it programmatically
    await handleDownload(url, `facture-${dossier.dossier_id}.pdf`);
  };
  const GeneratePdfFacture = async () => {
    const res = await TriggerPdfGeneration(
      dossier.dossier_id,
      dossier.garage_id
    );
    const url = `${process.env.REACT_APP_BACK_BASE_URL}/${res.data.facture}`;
    // Trigger download by creating a hidden link and clicking it programmatically
    window.open(url, "_blank");
  };
  // function to download pdf
  const DownloadPdfCCASigned = async () => {
    const url = `${process.env.REACT_APP_BACK_BASE_URL}/${dossier.cession_creance}`;
    // Trigger download by creating a hidden link and clicking it programmatically
    await handleDownload(
      url,
      `Cession-creance-${dossier.dossier_id}-signé.pdf`
    );
  };
  // Function to handle file download
  const handleDownload = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set the file name for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle error as needed
    }
  };

  // facture modal
  const handleAddField = () => {
    setFields([...fields, { key: '', prix: 0, quantity: 0}]);
  };

  const handleRemoveField = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
  };

  const handleFactureModalChange = (index, field, value) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = field !== 'key' ? Number(value) : value;
    setFields(updatedFields);
  };
  const handleSubmitFacture = async(e) => {
    e.preventDefault();
    // Convert fields array back to an object for submission
    const additional_fields = fields.reduce((acc, { key, prix, quantity }) => {
      if (key) acc[key] = { prix, quantity };
      return acc;
    }, {});
    // Update dossier with new additional fields
    await DossierUpdate(
      dossier.dossier_id,
      { additional_fields },
      UserDetails.userid,
      UserDetails.usertype
    );
    setShowFactureModal(false);
  };
  //end facture modal

  let itemData = [];
  let documentData = [];

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
  const documentExtensions = ["pdf", "doc", "docx", "xls", "xlsx"];

  function isImage(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();
    return imageExtensions.includes(extension);
  }
  function isDocument(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();
    return documentExtensions.includes(extension);
  }
  function processPhotos(photos, titlePrefix) {
    photos
      .split("|")
      .filter((path) => path.trim() !== "")
      .forEach((photo, index) => {
        const fileUrl = `${baseUrl}/${photo}`;
        if (isImage(photo)) {
          itemData.push({
            img: fileUrl,
            title: `${titlePrefix} ${index + 1}`,
          });
        } else if (isDocument(photo)) {
          documentData.push({
            url: fileUrl,
            title: `${titlePrefix} ${index + 1}`,
            type: photo.split(".").pop().toUpperCase(),
          });
        }
      });
  }

  if (dossier.cession_creance) {
    processPhotos(dossier.cession_creance, "Cession de creance");
  }
  if (dossier.facture) {
    processPhotos(dossier.facture, "Facture");
  }
  if (dossier.glass_photo) {
    processPhotos(dossier.glass_photo, "Photo du vitrage");
  }
  if (dossier.green_card_photo) {
    processPhotos(dossier.green_card_photo, "Photo de la carte verte");
  }
  if (dossier.vehicle_registration_photo) {
    processPhotos(
      dossier.vehicle_registration_photo,
      "Photo de la carte grise"
    );
  }

  itemData = itemData.filter((item) => item.img);
  documentData = documentData.filter((doc) => doc.url);

  /// STEPPER ///
  // Modals
  const handleShowFactureModal = () => setShowFactureModal(true);
  const handleCloseFactureModal = () => setShowFactureModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleGarageShowModal = () => {
    setGarageShowModal(true);
    assignGarageToDossierData();
  };
  const handleShowGarageValidationModal = () => {
    setShowGarageValidationModal(true);
  };
  const handleGarageCloseModal = () => {
    setGarageShowModal(false);
  };
  const handleCloseModal = () => setShowModal(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleCloseGarageValidationModal = () =>
    setShowGarageValidationModal(false);

  // Fetch garage data
  const assignGarageToDossierData = async () => {
    try {
      const garagesData = await getUserData("garage", userid);
      setGarages(garagesData);
    } catch (error) {
      console.error("Error fetching garages:", error);
    }
  };

  // Render status chips
  const statusColors = {
    "En cours": "#1E90FF",
    "BDG oui": "#002654",
    "Attente de pose": "#87CEEB",
    Signature: "#00BFFF",
    "Rendez vous": "#4682B4",
    "Pose terminé": "#32CD32",
    "Envoie de CCA": "#3CB371",
    Relance: "#FF9E0D",
    "Dossier terminé": "#ff224d",
    Annuler: "#D61E1E",
  };
  const getStatusLabel = (status) => {
    switch (status) {
      case "En cours":
        return "En cours";
      case "BDG oui":
        return "BDG oui";
      case "Attente de pose":
        return "Attente de pose";
      case "Signature":
        return "Signature";
      case "Rendez vous":
        return "Rendez vous";
      case "Pose terminé":
        return "Pose terminé";
      case "Envoie de CCA":
        return "Envoie de CCA";
      case "Relance":
        return "Relance";
      case "Dossier terminé":
        return "Dossier terminé";
      default:
        return "";
    }
  };

  // Status steps
  const steps = [
    "En cours",
    "BDG oui",
    "Attente de pose",
    "Signature",
    "Rendez vous",
    "Pose terminé",
    "Envoie de CCA",
    "Relance",
    "Dossier terminé",
  ];
  // Determine if a step has failed
  const isStepFailed = (step) => {
    return (
      dossier?.status &&
      dossier.status.includes("Annuler") &&
      dossier.status.includes(steps[step])
    );
  };

  // Determine button label
  const getNextButtonLabel = () => {
    if (dossier?.status.includes("Annuler")) {
      return (
        <>
          <LockOpen /> Réouvrir
        </>
      );
    }
    return ["Relance", "Dossier terminé"].includes(dossier?.status) ? (
      <>
        <Check /> Fermé
      </>
    ) : (
      <>
        Suivant <SkipNext />
      </>
    );
  };

  // Handle "Next" Disabled
  const isNextButtonDisabled = () => {
    if (!dossier?.status) {
      return true;
    }

    // If the user is a gestionnaire and the status is "Attente de pose"
    if (
      usertype === "gestionnaire" &&
      (dossier.status === "Attente de pose" || dossier.status === "Rendez vous")
    ) {
      return true;
    }

    // If the status is "Dossier terminé"
    return dossier.status === "Dossier terminé";
  };
  const isPrevDisabled = () => {
    if (!dossier?.status) {
      return true;
    }
    if (dossier.status === steps[0]) {
      return true;
    }
    return false;
  };

  // Handle "Next" or "Finish" button click
  const handleNext = async () => {
    try {
      if (!dossier || !dossier.status || dossier.status.trim() === "") {
        return;
      }

      // Check if garage needs to be assigned
      if (
        dossier.status === "Attente de pose" &&
        !showGarageModal &&
        !dossier.garage_id
      ) {
        handleGarageShowModal();
        return;
      }

      // Proceed with the status update
      let statusWithoutAnnuler =
        dossier.status?.replace("|Annuler", "") || dossier.status;

      if (dossier.status.includes("|Annuler")) {
        await DossierUpdate(
          dossier.dossier_id,
          { status: statusWithoutAnnuler },
          UserDetails.userid,
          UserDetails.usertype
        );
        setDossier((prevDossier) => ({
          ...prevDossier,
          status: statusWithoutAnnuler,
        }));
      } else {
        const currentIndex = steps.indexOf(statusWithoutAnnuler);
        if (currentIndex !== -1 && currentIndex < steps.length - 1) {
          const nextStatus = steps[currentIndex + 1];
          const updates = { status: nextStatus };

          // Include garage_id in updates if we are at the "Attente de pose" step and selectedGarageId exists
          if (dossier.status === "Attente de pose" && selectedGarageId) {
            updates.garage_id = selectedGarageId;
          }

          await DossierUpdate(
            dossier.dossier_id,
            updates,
            UserDetails.userid,
            UserDetails.usertype
          );

          setDossier((prevDossier) => ({
            ...prevDossier,
            ...updates,
          }));
          setActiveStep(steps.indexOf(nextStatus));
        } else if (["Relance", "Dossier terminé"].includes(dossier?.status)) {
          await DossierUpdate(
            dossier.dossier_id,
            { status: "Dossier terminé" },
            UserDetails.userid,
            UserDetails.usertype
          );
          setDossier((prevDossier) => ({
            ...prevDossier,
            status: "Dossier terminé",
          }));
        }
      }
    } catch (error) {
      console.error("Failed to update dossier status:", error);
    }
  };
  // Handle "Prev" or "Finish" button click
  const handlePrev = async () => {
    try {
      if (!dossier || !dossier.status || dossier.status.trim() === "") {
        return;
      }
      if (dossier.status === steps[0]) {
        return;
      }

      // Proceed with the status update
      let statusWithoutAnnuler =
        dossier.status?.replace("|Annuler", "") || dossier.status;

      const currentIndex = steps.indexOf(statusWithoutAnnuler);
      if (currentIndex !== -1 && currentIndex > 0) {
        const prevStatus = steps[currentIndex - 1];
        const updates = { status: prevStatus };

        // Include garage_id = null in updates if we are at the "Signature" step
        if (dossier.status === "Signature") {
          updates.garage_id = "unassign";
          setSelectedGarageId("unassign");
        }

        await DossierUpdate(
          dossier.dossier_id,
          { ...updates }, //unassign condition on backend
          UserDetails.userid,
          UserDetails.usertype
        );
        setDossier((prevDossier) => ({
          ...prevDossier,
          ...updates,
          garage_id: null,
        }));
        setSelectedGarageId(null);
        setActiveStep(steps.indexOf(prevStatus));
        setShowGarageValidationModal(false);
      }
    } catch (error) {
      console.error("Failed to update dossier status:", error);
    }
  };
  const handleGarageSelectAndNext = async () => {
    try {
      if (selectedGarageId) {
        await DossierUpdate(
          dossier.dossier_id,
          { garage_id: selectedGarageId },
          UserDetails.userid,
          UserDetails.usertype
        );
        setDossier((prevDossier) => ({
          ...prevDossier,
          garage_id: selectedGarageId,
        }));
      }
      handleNext();
      handleGarageCloseModal();
    } catch (error) {
      console.error("Failed to assign garage and update status:", error);
    }
  };

  // Handle "Cancel" button click
  const handleCancel = () => {
    if (usertype === "gestionnaire" && dossier?.status === "Attente de pose") {
      //TODO: here update with signature
      return;
    }
    handleShowModal();
  };

  // Handle "Cancel" button Confirmation
  const handleConfirmCancel = async () => {
    try {
      if (!dossier?.status) return;

      const currentIndex = steps.indexOf(dossier.status);
      const cancelStatus = `${steps[currentIndex]}|Annuler`;
      await DossierUpdate(
        dossier.dossier_id,
        { status: cancelStatus },
        userid,
        usertype
      );
      setDossier((prevDossier) => ({
        ...prevDossier,
        status: cancelStatus,
      }));
      setActiveStep(0);
      handleCloseModal();
    } catch (error) {
      console.error("Failed to update dossier status:", error);
    }
  };
  // Handle "Delete" button click
  const handleDelete = () => {
    if (usertype === "gestionnaire") {
      //TODO: here update with signature
      return;
    }
    if (
      usertype === "call_center" ||
      usertype === "garage" ||
      usertype === "gestionnaire"
    ) {
      return;
    }
    handleShowDeleteModal();
  };
  // Handle "Delete" button Confirmation
  const handleConfirmDelete = async () => {
    try {
      if (!dossier?.status) return;

      await deleteDossierService(dossier.dossier_id, userid);
      navigate("/Home");
    } catch (error) {
      console.error("Failed to update dossier status:", error);
    }
  };

  // Render the status chips

  const renderStatusChips = () => {
    if (!dossier || !dossier.status) return null;

    const statusWithoutAnnuler = dossier.status.replace("|Annuler", "");
    const hiddenStatusesForCallCenter = [
      "Relance",
      "Envoie de CCA",
      "Dossier terminé",
    ];

    let currentIndex = steps.indexOf(statusWithoutAnnuler);

    // Debugging: Check usertype and current status
    console.log("User Type:", usertype);
    console.log("Current Status:", statusWithoutAnnuler);

    if (
      usertype === "call_center" &&
      hiddenStatusesForCallCenter.includes(statusWithoutAnnuler)
    ) {
      console.log("Condition met for Pose terminé");
      currentIndex = steps.indexOf("Pose terminé");
    }

    const previousIndex = currentIndex > 0 ? currentIndex - 1 : null;
    const nextIndex =
      currentIndex < steps.length - 1 &&
      !(
        usertype === "call_center" &&
        hiddenStatusesForCallCenter.includes(steps[currentIndex + 1])
      )
        ? currentIndex + 1
        : null;
    const isAnnuler = dossier.status.includes("|Annuler");

    return (
      <Box>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "15px" }}>
          {previousIndex !== null && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                gap: "10px",
              }}
            >
              <Chip
                label={steps[previousIndex]}
                sx={{
                  backgroundColor:
                    currentIndex !== null && currentIndex === previousIndex
                      ? statusColors[steps[previousIndex]]
                      : "#F5F5F5",
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  color:
                    currentIndex !== null && currentIndex === previousIndex
                      ? statusColors[steps[previousIndex]]
                      : "#808080",
                }}
              >
                Précédent
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              gap: "10px",
            }}
          >
            <Chip
              label={
                usertype === "call_center" &&
                hiddenStatusesForCallCenter.includes(statusWithoutAnnuler)
                  ? "Pose Terminé"
                  : steps[currentIndex]
              }
              sx={{
                color: "#fff",
                backgroundColor:
                  currentIndex !== null
                    ? statusColors[steps[currentIndex]]
                    : "#F5F5F5",
              }}
            />
            <Typography
              variant="caption"
              sx={{
                color:
                  currentIndex !== null && currentIndex === previousIndex
                    ? statusColors[steps[previousIndex]]
                    : "#808080",
              }}
            >
              Actuel
            </Typography>
          </Box>

          {nextIndex !== null && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                gap: "10px",
              }}
            >
              <Chip
                label={steps[nextIndex]}
                sx={{
                  backgroundColor:
                    currentIndex !== null && currentIndex === nextIndex
                      ? statusColors[steps[nextIndex]]
                      : "#F5F5F5",
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  color:
                    currentIndex !== null && currentIndex === previousIndex
                      ? statusColors[steps[previousIndex]]
                      : "#808080",
                }}
              >
                Suivant
              </Typography>
            </Box>
          )}
        </Box>

        {usertype !== "call_center" && usertype !== "garage" && (
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={handleCancel}
              disabled={dossier?.status.includes("Annuler")}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#002654",
                "&:hover": {
                  backgroundColor: "#ff224d",
                },
              }}
              size="small"
              onClick={handleNext}
              disabled={isNextButtonDisabled()}
            >
              {getNextButtonLabel()}
            </Button>
          </Box>
        )}
        {usertype !== "call_center" && usertype !== "garage" && (
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={handleDelete}
              sx={{
                visibility: usertype === "gestionnaire" ? "hidden" : "visible",
              }}
              disabled={usertype !== "admin"}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={handleShowGarageValidationModal}
              disabled={isPrevDisabled()}
            >
              prev
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  /// DOSSIER EDITE ///
  const handleEditeShowModal = () => {
    setEditeShowModal(true);
  };
  const handleEditeCloseModal = () => {
    setEditeShowModal(false);
  };

  const handleAddShowModal = () => {
    setAddShowModal(true);
  };
  const handleAddCloseModal = () => {
    setAddShowModal(false);
  };

  const handleRendezVousModal = () => {
    setRendezVousModal(true);
  };
  const handleRendezVousCloseModal = () => {
    setRendezVousModal(false);
  };
  const handleUploadFacturationModal = () => {
    setUploadFacturationModel(true);
  };
  const handleUploadFacturationCloseModel = () => {
    setUploadFacturationModel(false);
  };
  const handleUploadFacturation = async () => {
    const formDataaa = new FormData();
    formDataaa.append("files", file);
    try {
      const res = await uploadFileService(formDataaa, "dossier");
      await UpdateFolder(dossier.dossier_id, {
        cession_creance: `uploads/${res.filePaths[0]}`,
      });
      setDossier((prevDossier) => ({
        ...prevDossier,
        cession_creance: `uploads/${res.filePaths[0]}`,
      }));
      setAlertSeverity("success");
      setAlertMessage("Facturation uploaded successfully!");
      setAlertOpen(true);
      handleUploadFacturationCloseModel();
    } catch (error) {
      console.error("Error uploading facturation:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to upload facturation");
      setAlertOpen(true);
    }
  };

  // AUTOCOMPLETE LIST
  const glass_type_list = [
    "Pare-brise",
    "Lunette arriére",
    "Vitre porte latérale avant ( GAUCHE)",
    "Vitre porte latérale avant ( DROITE)",
    "Vitre porte latérale arrière ( GAUCHE)",
    "Vitre porte latérale arrière ( DROITE)",
    "Pavillon panoramique",
    "Vitre triangulaire avant ( GUACHE)",
    "Vitre triangulaire avant ( DROITE)",
    "Vitre arrière porte latérale arrière ( GAUCHE)",
    "Vitre arrière porte latérale arrière ( DROITE)",
    "Lunette arriére inférieure",
    "Lunette arriére supérieure",
    "Vitre custode arrière ( GAUCHE)",
    "Vitre custode arrière ( DROITE)",
    "Vitre arrière custode arrière ( GAUCHE)",
    "Vitre arrière custode arrière ( DROITE)",
    "Vitres portes chargement arrière (Utilitaire) - [Gauche]",
    "Vitres portes chargement arrière (Utilitaire) - [Droite]",
    "Ensemble vitre panneau latéral - [Gauche]",
    "Ensemble vitre panneau latéral - [Droite]",
    "Vitre avant panneau latéral - [Gauche]",
    "Vitre avant panneau latéral - [Droite]",
    "Vitre coulissante avant panneau latéral - [Gauche]",
    "Vitre coulissante avant panneau latéral - [Droite]",
    "Vitre centrale panneau latéral - [Gauche]",
    "Vitre centrale panneau latéral - [Droite]",
    "Vitre coulissante centrale panneau latéral - [Gauche]",
    "Vitre coulissante centrale panneau latéral - [Droite]",
    "Vitre arrière panneau latéral - [Gauche]",
    "Vitre arrière panneau latéral - [Droite]",
    "Vitre coulissante arrière panneau latéral - [Gauche]",
    "Vitre coulissante arrière panneau latéral - [Droite]",
    "Vitre avant de panneau latéral arrière - [Gauche]",
    "Vitre avant de panneau latéral arrière - [Droite]",
    "Vitre arrière de panneau latéral arrière - [Gauche]",
    "Vitre arrière de panneau latéral arrière - [Droite]",
    "Vitre rallonge de panneau latéral arrière  - [Gauche]",
    "Vitre rallonge de panneau latéral arrière  - [Droite]",
    "Vitre supérieure panneau latéral - [Gauche]",
    "Vitre supérieure panneau latéral - [Droite]",
    "Pavillon avant – [Gauche]",
    "Pavillon avant – [Droite]",
    "Pavillon arrière – [Gauche]",
    "Pavillon arrière – [Droite]",
    "Toit ouvrant avant vitré (complet)",
    "Vitre toit ouvrant central",
    "Toit ouvrant arrière vitré (complet)",
    "Vitre toit ouvrant arrière",
    "Toit ouvrant complet à lamelles",
    "Lamelle 1 de toit ouvrant",
    "Lamelle 2 de toit ouvrant",
    "Lamelle 3 de toit ouvrant",
    "Lamelle 4 de toit ouvrant",
    "Lamelle 5 de toit ouvrant",
    "Phare avant (complet) - [Gauche]",
    "Phare avant (complet) - [Droite]",
    "Phare avant (extérieur) - [Gauche]",
    "Phare avant (extérieur) - [Droite]",
    "Vitre phare avant – [Gauche]",
    "Vitre phare avant – [Droite]",
    "Phare antibrouillard avant (complet) – [Gauche]",
    "Phare antibrouillard avant (complet) – [Droite]",
    "Phare antibrouillard avant – [Gauche]",
    "Phare antibrouillard avant – [Droite]",
    "Clignotant avant (complet) – [Gauche]",
    "Clignotant avant (complet) – [Droite]",
    "Feu arrière (complet) – [Gauche]",
    "Feu arrière (complet) – [Droite]",
    "Feu arrière (extérieur) – [Gauche]",
    "Feu arrière (extérieur) – [Droite]",
    "Feu arrière complet (intérieur) – [Gauche]",
    "Feu arrière complet (intérieur) – [Droite]",
    "Ecran feu arrière (extérieur) – [Gauche]",
    "Ecran feu arrière (extérieur) – [Droite]",
    "Ecran feu arrière (intérieur) – [Gauche]",
    "Ecran feu arrière (intérieur) – [Droite]",
    "Rétroviseur extérieur (complet) – [Gauche]",
    "Rétroviseur extérieur (complet) – [Droite]",
    "Rétroviseur intérieur",
    "Vitre rétroviseur extérieur avant – [Gauche]",
    "Vitre rétroviseur extérieur avant – [Droite]",
    "Vitre inférieure rétroviseur (VU) – [Gauche]",
    "Vitre inférieure rétroviseur (VU) – [Droite]",
  ];
  const incident_reason_list = [
    "une projection sur la route",
    "un vandalisme",
    "une intempérie",
    "un accident",
    "en stationnement",
    "un nid de poule",
    "des gravillons",
    "de la grêle",
    "un vol",
    "une variation thermique",
  ];
  const insurance_name_list = [
    "MAVIT ASSURANCES",
    "4 ASSUR",
    "ASSU2000",
    "ACTE IARD",
    "ACTIVE ASSURANCES",
    "AG2R LA MONDIALE",
    "AIG EUROPE LIMITED",
    "AIR-ALPHA ASSURANCES",
    "ALLIANZ IARD S.A.",
    "APRIL PARTENAIRES",
    "AMV",
    "AGPM ASSURANCES",
    "ASSURANCE MUTUELLE DES MOTARDS",
    "ABEILLE ASSURANCES",
    "ADEP",
    "ADMIRAL INTERMEDIARY SERVICES SA",
    "AREAS DOMMAGES",
    "ALBINGIA",
    "ALLSECUR",
    "ALTIMA ASSURANCES",
    "AIOI NISSAY DOWA EUROPE",
    "APIVIA COURTAGE",
    "APRIL COLLECTION ET LOISIRS",
    "ACM IARD",
    "ASSUREO",
    "ASSURPEOPLE",
    "GROUPAMA PARIS VAL DE LOIRE",
    "LA SAUVEGARDE",
    "BNP PARIBAS",
    "CARENE ASSURANCES",
    "CAISSE MUTUELLE MARNAISE D ASSURANCE",
    "CARDIF IARD",
    "BPCE IARD",
    "CHUBB EUROPEAN GROUP LIMITED",
    "DIRECT ASSURANCE",
    "CMMA ASSURANCE",
    "BALTIQUEASSURANCES",
    "ASSURANCES MUTUELLES DE PICARDIE",
    "ASSURONE GROUP",
    "ASSURONLINE",
    "GROUPE SATEC",
    "ASSURANCES DU CREDIT MUTUEL IARD",
    "AXERIA IARD",
    "ECA",
    "EURALPHA ASSURANCES",
    "GROUPAM RHONE-ALPES AUVERGNE",
    "EURODATACAR",
    "LSA COURTAGE",
    "FILHET-ALLARD",
    "AXA FRANCE IARD",
    "GENERALI IARD",
    "AVANSSUR",
    "L EQUITE",
    "EURO-ASSURANCE",
    "GROUPAMA ANTILLES GUYANE",
    "GROUPAMA CENTRE-ATLANTIQUE",
    "GROUPAMA GRAND EST",
    "GROUPAMA LOIRE BRETAGNE",
    "GROUPAMA MEDITERRANEE",
    "GROUPAMA NORD-EST",
    "GROUPAMA D OC",
    "EUROFIL",
    "CARMA",
    "LA MEDICALE",
    "GROUPE AMI3F",
    "IPAC 64",
    "CAISSE MEUSIENNE D ASSURANCES MUTUELLES",
    "FRANCOIS BERNANRD ASSURANCES",
    "GRAS SAVOYE",
    "I2FC",
    "IDENTICAR",
    "INTER MUTUELLES ENTREPRISES",
    "L AUXILIAIRE",
    "LA BRESSANE",
    "LA BRESSANE ASSURANCES",
    "LA RURALE",
    "LIBEA",
    "LYBERNET ASSURANCES",
    "MAAF ASSURANCES SA",
    "MACIF",
    "MACIFILIA",
    "MATMUT",
    "PSA ASSURANCE",
    "MAPA",
    "NOVELIA",
    "PRUDENCE CREOLE",
    "PROTEC BTP",
    "PRIMA",
    "PACIFICA",
    "MATMUT & CO",
    "MAIF",
    "MMA IARD",
    "SURAVENIR ASSURANCES",
    "SOGESSUR",
    "MFA",
    "MACSF",
    "THELEM ASSURANCES",
    "SWISS LIFE",
    "SMABTP",
    "SERENIS ASSURANCES",
    "MONCEAU GENERALE ASSURANCES",
    "MAXANCE ASSURANCES",
    "OYAT ASSURANCES",
    "SOLLY AZAR ASSURANCES",
    "MUTUELLE SAINT CHRISTOPHE",
    "MARSH SAS",
    "SADA ASSURANCES",
    "SARPGN",
    "QUATTRO ASSURANCES",
    "REMA ASSURANCES MUTUELLES",
    "SHAM",
    "SAGESSE ASSURANCES",
    "SMA SA",
    "SMACL ASSURANCES",
    "SOCIETE SUISSE D ASSURANCE CONTRE LA GRELE",
    "TOYOTA ASSURANCES",
    "MUTUELLE D'ASSURANCE DES ARMEES",
    "MUTUELLE D'ASSURANCE DE LA VILLE DE THANN",
    "MAVIM",
    "MUTUELLE ASSURANCES CORPS SANTE FRANCAIS",
    "MUTUELLE ALSACE LORRAINE JURA",
    "MATH-PREVERAIS",
    "MUDETAF",
    "MADP ASSURANCES",
    "MUTUELLE FRATERNELLE ASSURANCE",
    "MAE",
    "MALJ",
    "RELYENS",
    "A COMME ASSURE",
    "WAKAM",
    "LCL",
    "ARTEMIS",
    "VERSPIEREN",
    "FMA",
    "LEOCARE",
    "JP LABALETTE",
    "L'OLIVIER",
    "AVIVA",
    "WAZARI",
    "GFA LACOUR",
    "XL INSURANCE COMPANY SE",
    "RENAULT ASSURANCE",
    "AMF ASSURANCES",
    "EURODAMMAGES",
    "CARREFOUR ASSURANCES",
    "GAN ASSURANCES",
    "CARMINE ASSURANCES",
    "BPCE ASSURANCES",
    "GROUPAMA CENTRE MANCHE",
    "GROUPAMA OCEAN INDIEN",
    "MUTUELLE DE L'EST",
    "CMAM",
    "RETRO ASSURANCES",
    "MUTUELLE D'ASSURANCE DES TECHNICIEN DE LA SECURITE ROUTIERE",
    "IMPERIO ASSURANCE",
    "ACHEEL",
    "LE FINISTERE ASSURANCE",
    "MUTUELLE D'ASSURANCE DE LA VILLE DE MULHOUSE",
    "DIF",
    "ASSURANCES PILLIOT",
    "PHENIX ASSURANCE",
    "FLITTER",
    "FRONTASSUR",
    "MIEUX ASSURE",
    "GMF ASSURANCES",
    "ARVAL SERVICE LEASE",
    "LA BANQUE POSTALE ASSURANCES IARD",
    "NETVOX ASSURANCE",
    "MUTUELLE DE POITIERS ASSURANCES",
    "CBT FERBU (THELEM)",
    "ASSU 2000",
    "ASSURTIS",
    "L'EQUITE",
    "AECS",
    "CIC CREDIT LYONNAIS",
    "COVERITY",
    "BNP AVANSSUR",
    "CABINET BASSEVILLE",
    "CABINET JACQUES",
    "KELIP'S",
    "CBT BFK ASSURANCES",
    "LOVYS",
    "ANGELUS",
    "AON FRANCE",
    "CBT CELTIQUE COURTAGE",
    "REFLEXE ASSURANCE",
    "CBT CEPIA",
    "INFINITY",
    "ELSASSUR",
    "EMIRION ASSURANCES (GENERALI)",
    "FATEC-GROUP",
    "GFA CARAIBES GENERALI",
    "WTW",
    "LEASPLAN FRANCE SAS",
    "LCA",
    "HELVETIA",
    "LINK ASSURANCES",
    "LUXIOR ASSURANCE",
    "SYLVIE CABRET",
    "PLURIEL ASSURANCES",
    "PLUS SIMPLE",
    "P,PLASSE",
    "SPRINGASSUR",
    "CFC",
    "KELIPS ASSURANCE",
    "XENASSUR",
    "ASSURANCES VERLINGUE",
    "CAP'ASSUR",
    "DJN ASSURANCES(ALLIANZ)",
    "APRIL ENTREPRISE CARAIBES",
    "LIGAP",
    "MUTUELLE D ASSURANCE DE L'ARTISANAT ET DES TRANSPORTS",
    "ORNIKAR",
    "MAF",
    "SM3A LYON",
    "TETRIS ASSURANCE",
    "AESIO MUTUELLE",
    "+ SIMPLE",
    "ATHLON CAR LEASE",
    "YEET ASSURANCES",
  ];
  // HANDLE DATA CHANGE FUNCTIONS
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  const handleAutocompleteChange = (e, value, fieldName) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  // HANDLE ALERT  FUNCTIONS
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  // HANDLE SUBMIT FUNCTION
  const handleEdit = async (e) => {
    if (formData.appointment_date === "1970-01-01") {
      try {
        const updatedFormData = { ...formData };
        const dossier_id = dossier.dossier_id; // Assuming dossier_id is accessible in scope

        // Update the folder with the updated form data
        const response = await UpdateFolder(dossier_id, updatedFormData);

        setAlertMessage(`Le dossier a été modifié avec succès.`);
        setAlertSeverity("success");
        setAlertOpen(true);
        window.location.reload();

        // Close the modals
        handleEditeCloseModal();
        setEditeShowModal(false);
        setAddShowModal(false);
        setRendezVousModal(false);
      } catch (error) {
        console.error("Update folder failed:", error);
        setAlertMessage(error.message);
        setAlertSeverity("error");
        setAlertOpen(true);
      }
    } else {
      if (formData.appointment_date) {
      }
      try {
        const updatedFormData = { ...formData };
        const dossier_id = dossier.dossier_id; // Assuming dossier_id is accessible in scope

        // Update the folder with the updated form data
        const response = await UpdateFolder(dossier_id, updatedFormData);
        window.location.reload();
        setAlertMessage(`Le dossier a été modifié avec succès.`);
        setAlertSeverity("success");
        setAlertOpen(true);
      } catch (error) {
        console.error("Update folder failed:", error);
        setAlertMessage(error.message);
        setAlertSeverity("error");
        setAlertOpen(true);
      }
    }
  };

  return (
    <Box component="div" className="custom-box">
      <Header />
      <Box sx={{ marginTop: "120px", display: "flex" }}>
        <Sidebar />

        <Snackbar
          open={alertOpen}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={handleAlertClose} severity={alertSeverity}>
            <AlertTitle>
              {alertSeverity === "success" ? "Succès" : "Erreur"}
            </AlertTitle>
            {alertMessage}
          </Alert>
        </Snackbar>

        <Notification />

        <Box
          sx={{
            width: window.innerWidth < 768 ? "99%" : "93%",
            marginLeft: window.innerWidth < 768 ? "5px" : "260px",
            marginRight: "5px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {/* STEPPER */}
          {dossier?.status ? (
            isMobile ? (
              renderStatusChips()
            ) : (
              <Box sx={{ width: "100%" }}>
                <Stepper
                  alternativeLabel
                  activeStep={
                    dossier?.status === "Dossier terminé" ||
                    dossier.status.trim() === ""
                      ? steps.length
                      : steps.indexOf(dossier?.status.replace("|Annuler", ""))
                  }
                  sx={{ flexDirection: "row", flexWrap: "wrap" }}
                >
                  {steps.map((label, index) => {
                    // Hide specific steps if user is a garage or call_center
                    const hideStepsForUserType = [
                      "Envoie de CCA",
                      "Relance",
                      "Dossier terminé",
                    ];
                    if (
                      ["call_center"].includes(usertype) &&
                      hideStepsForUserType.includes(label)
                    ) {
                      return null; // Skip rendering these steps
                    }

                    const labelProps = {};
                    if (isStepFailed(index)) {
                      labelProps.optional = (
                        <Typography variant="caption" color="error">
                          Annuler
                        </Typography>
                      );
                      labelProps.error = true;
                    }
                    return (
                      <Step key={label}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>

                {usertype !== "call_center" && usertype !== "garage" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={handleCancel}
                      disabled={dossier?.status.includes("Annuler")}
                      sx={{
                        width: "120px",
                      }}
                    >
                      Annuler
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        width: "120px",
                        backgroundColor: "#002654",
                        "&:hover": {
                          backgroundColor: "#ff224d",
                        },
                      }}
                      size="small"
                      onClick={handleNext}
                      disabled={isNextButtonDisabled()}
                    >
                      {getNextButtonLabel()}
                    </Button>
                  </Box>
                )}
                {usertype !== "call_center" && usertype !== "garage" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleDelete}
                      disabled={usertype === "gestionnaire" ? true : false}
                      sx={{
                        bgcolor: "rgba(255, 34, 77, 0.8)",
                        width: "120px",
                        visibility:
                          usertype === "gestionnaire" ? "hidden" : "visible",
                      }}
                    >
                      Supprimer <Delete />
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleShowGarageValidationModal}
                      sx={{
                        width: "120px",
                        backgroundColor: "#002654",
                        "&:hover": {
                          backgroundColor: "#ff224d",
                        },
                      }}
                      disabled={isPrevDisabled()}
                    >
                      <SkipPrevious /> Précédent
                    </Button>
                  </Box>
                )}
              </Box>
            )
          ) : null}

          {/*  Cancel Modale */}
          <Modal
            open={showModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "10px solid transparent",
                boxShadow: 24,
                p: 1,
                borderRadius: 4,
              }}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                Confirmation
              </Typography>
              <Typography id="modal-description" sx={{ mt: 2 }}>
                Es-tu sûre de vouloir annuler?
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseModal}
                  sx={{ mr: 2 }}
                >
                  Non
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#002654",
                    "&:hover": {
                      backgroundColor: "#ff224d",
                    },
                  }}
                  onClick={handleConfirmCancel}
                >
                  Oui
                </Button>
              </Box>
            </Box>
          </Modal>
          {/*  Delete Modale */}
          <Modal
            open={showDeleteModal}
            onClose={handleCloseDeleteModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "10px solid transparent",
                boxShadow: 24,
                p: 1,
                borderRadius: 4,
              }}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                Confirmation
              </Typography>
              <Typography id="modal-description" sx={{ mt: 2 }}>
                Es-tu sûre de vouloir supprimer?
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseDeleteModal}
                  sx={{ mr: 2 }}
                >
                  Non
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#002654",
                    "&:hover": {
                      backgroundColor: "#ff224d",
                    },
                  }}
                  onClick={handleConfirmDelete}
                >
                  Oui
                </Button>
              </Box>
            </Box>
          </Modal>
          {/*  prev button Modale */}
          <Modal
            open={showGarageValidationModal}
            onClose={handleCloseGarageValidationModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "10px solid transparent",
                boxShadow: 24,
                p: 1,
                borderRadius: 4,
              }}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                Confirmation
              </Typography>
              <Typography id="modal-description" sx={{ mt: 2 }}>
                Es-tu sûr(e) de vouloir revenir en arrière ?
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseGarageValidationModal}
                  sx={{ mr: 2 }}
                >
                  Non
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#002654",
                    "&:hover": {
                      backgroundColor: "#ff224d",
                    },
                  }}
                  onClick={handlePrev}
                >
                  Oui
                </Button>
              </Box>
            </Box>
          </Modal>

          {/*  Garage Modale */}
          <Modal
            open={showGarageModal}
            onClose={handleGarageCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "10px solid transparent",
                boxShadow: 24,
                p: 1,
                borderRadius: 4,
              }}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                Choose Garage
              </Typography>
              <Autocomplete
                options={garages}
                getOptionLabel={(option) => option.garage_name}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedGarageId(newValue.garage_id);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Garage" />
                )}
              />

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleGarageCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  mr={3}
                  variant="contained"
                  sx={{
                    backgroundColor: "#002654",
                    "&:hover": {
                      backgroundColor: "#ff224d",
                    },
                  }}
                  onClick={handleGarageSelectAndNext}
                >
                  Assign
                </Button>
              </Box>
            </Box>
          </Modal>

          {/* Edite Modale */}
          <Modal
            open={showEditeModal}
            onClose={handleEditeCloseModal}
            aria-labelledby="edit-dossier-modal-title"
            aria-describedby="edit-dossier-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                border: "1px solid #000",
                borderRadius: "4px",
                boxShadow: 24,
                p: 1,
                borderRadius: 4,
                maxHeight: "80vh",
                overflow: "auto",
              }}
            >
              <Box>
                <Typography variant="h4" sx={{ my: 2 }}>
                  Information requise
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      onChange={handleChange}
                      id="name_or_company"
                      label="Nom ou Société"
                      name="name_or_company"
                      type="text"
                      autoFocus
                      size="small"
                      value={formData.name_or_company}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      onChange={handleChange}
                      id="first_name"
                      label="Prénom"
                      name="first_name"
                      size="small"
                      value={formData.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      onChange={handleChange}
                      id="email"
                      label="Email"
                      name="email"
                      size="small"
                      value={formData.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PhoneInput
                      country={"fr"}
                      value={formData.tel}
                      onChange={(phone) =>
                        handleChange({ target: { name: "tel", value: phone } })
                      }
                      inputProps={{
                        name: "tel",
                        required: true,
                        autoFocus: false,
                      }}
                      containerStyle={{ width: "100%" }}
                      inputStyle={{ width: "100%", height: "40px" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      onChange={handleChange}
                      name="city"
                      label="Ville"
                      id="city"
                      size="small"
                      value={formData.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      onChange={handleChange}
                      name="address"
                      label="Adresse"
                      id="address"
                      size="small"
                      value={formData.address}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      onChange={handleChange}
                      name="postal_code"
                      label="Code Postal"
                      id="postal_code"
                      size="small"
                      value={formData.postal_code}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      onChange={handleChange}
                      name="license_plate"
                      label="Plaque d'immatriculation"
                      id="license_plate"
                      size="small"
                      value={formData.license_plate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      onChange={handleChange}
                      name="vehicle_model"
                      label="Modèle du véhicule"
                      id="vehicle_model"
                      size="small"
                      value={formData.vehicle_model}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      disablePortal
                      id="insurance_name"
                      name="insurance_name"
                      value={
                        insurance_name_list.includes(formData.insurance_name)
                          ? formData.insurance_name
                          : ""
                      }
                      onChange={(e, value) =>
                        handleAutocompleteChange(e, value, "insurance_name")
                      }
                      options={insurance_name_list}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Nom de l'assurance"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      name="insurance_name"
                      label="Autre Assurance"
                      id="autreAssurance"
                      size="small"
                      value={
                        !insurance_name_list.includes(formData.insurance_name)
                          ? formData.insurance_name
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      onChange={handleChange}
                      name="insurance_policy_number"
                      label="Numéro de police"
                      id="insurance_policy_number"
                      size="small"
                      value={formData.insurance_policy_number}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      required
                      fullWidth
                      onChange={handleChange}
                      name="date_incident"
                      label="Date de sinistre"
                      type="date"
                      id="date_incident"
                      size="small"
                      value={formData.date_incident}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      required
                      fullWidth
                      onChange={handleChange}
                      name="date_declaration"
                      label="Date de déclaration"
                      type="date"
                      id="date_declaration"
                      size="small"
                      value={formData.date_declaration}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      disablePortal
                      id="incident_reason"
                      name="incident_reason"
                      value={formData.incident_reason}
                      options={incident_reason_list}
                      onChange={(e, value) =>
                        handleAutocompleteChange(e, value, "incident_reason")
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Raison de sinistre"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      disablePortal
                      id="glass_type"
                      name="glass_type"
                      value={formData.glass_type}
                      onChange={(e, value) =>
                        handleAutocompleteChange(e, value, "glass_type")
                      }
                      options={glass_type_list}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Type de vitrage"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Typography variant="h4" sx={{ my: 2 }}>
                  Informations non requises
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      name="gift_type"
                      label="Type de cadeau"
                      id="gift_type"
                      size="small"
                      value={formData.gift_type}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      name="incident_number"
                      label="Numéro de sinistre"
                      id="incident_number"
                      size="small"
                      value={formData.incident_number}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      name="vehicle_mileage"
                      label="Kilométrage du véhicule"
                      id="vehicle_mileage"
                      size="small"
                      value={formData.vehicle_mileage}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      name="installation_address"
                      label="Adresse d'installation"
                      id="installation_address"
                      size="small"
                      value={formData.installation_address}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      name="internal_reference"
                      label="Référence interne"
                      id="internal_reference"
                      size="small"
                      value={formData.internal_reference}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="professional"
                          checked={Boolean(formData.professional)}
                          onChange={handleCheckboxChange}
                          color="primary"
                        />
                      }
                      label="Est-ce un professionnel ?"
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleEditeCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#002654",
                      "&:hover": {
                        backgroundColor: "#ff224d",
                      },
                    }}
                    onClick={handleEdit}
                  >
                    Modifier le dossier
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
          {/* facture Modale */}
          <Modal
            open={showFactureModal}
            onClose={handleCloseFactureModal}
            aria-labelledby="edit-facture-modal-title"
            aria-describedby="edit-facture-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                border: "1px solid #000",
                borderRadius: "4px",
                boxShadow: 24,
                p: 1,
                borderRadius: 4,
                maxHeight: "80vh",
                overflow: "auto",
              }}
            >
              <form onSubmit={handleSubmitFacture}>
                <Typography variant="h4" sx={{ my: 2 }}>
                  Facture details
                </Typography>
                <Grid container spacing={2}>
                  {fields.map((field, index) => (
                    <React.Fragment key={index}>
                    <Grid item xs={12} sm={8} md={6}>
                      <TextField
                        label="Description"
                        variant="outlined"
                        fullWidth
                        value={field.key}
                        onChange={(e) => handleFactureModalChange(index, 'key', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                      <TextField
                        label="Prix"
                        variant="outlined"
                        fullWidth
                        type="number"
                        value={field.prix}
                        onChange={(e) => handleFactureModalChange(index, 'prix', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                      <TextField
                        label="Quantity"
                        variant="outlined"
                        fullWidth
                        type="number"
                        value={field.quantity}
                        onChange={(e) => handleFactureModalChange(index, 'quantity', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        color="primary"
                        onClick={handleAddField}
                        disabled={index !== fields.length - 1}
                      >
                        <AddCircle />
                      </IconButton>
                      {fields.length > 1 && (
                        <IconButton color="error" onClick={() => handleRemoveField(index)}>
                          <RemoveCircle />
                        </IconButton>
                      )}
                    </Grid>
                  </React.Fragment>
                  ))}
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '16px' }}
                >
                  Soumettre
                </Button>
              </form>
            </Box>
          </Modal>

          {/* Add info Modale */}

          {/* Gestionnaire and admin  */}
          <Modal
            open={addShowModal}
            onClose={handleAddCloseModal}
            aria-labelledby="add-dossier-modal-title"
            aria-describedby="add-dossier-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                border: "1px solid #000",
                borderRadius: "4px",
                boxShadow: 24,
                p: 1,
                borderRadius: 4,
                maxHeight: "90vh",
                overflow: "auto",
              }}
            >
              <Box>
                <Typography variant="h4" sx={{ my: 2 }}>
                  Ajouter des Informations
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Commentaire Assurance"
                      onChange={handleChange}
                      id="insurance_comment"
                      name="insurance_comment"
                      value={formData.insurance_comment}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      name="incident_number"
                      label="Numéro de sinistre"
                      id="incident_number"
                      size="small"
                      value={formData.incident_number}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <NumericFormat
                      customInput={TextField}
                      fullWidth
                      label="Montant franchise"
                      onValueChange={(values) => {
                        const { value } = values;
                        handleChange({
                          target: { name: "deductible_amount", value },
                        });
                      }}
                      size="small"
                      id="deductible_amount"
                      name="deductible_amount"
                      value={formData.deductible_amount}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix="€ "
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="VIN"
                      onChange={handleChange}
                      id="mine_type"
                      name="mine_type"
                      value={formData.mine_type}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="insured_client"
                          checked={Boolean(formData.insured_client)}
                          onChange={handleCheckboxChange}
                          color="primary"
                        />
                      }
                      label="Client Assuré ?"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="verified_deductible"
                          checked={Boolean(formData.verified_deductible)}
                          onChange={handleCheckboxChange}
                          color="primary"
                        />
                      }
                      label="Franchise Vérifiée ?"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="sent_insurance"
                          checked={Boolean(formData.sent_insurance)}
                          onChange={handleCheckboxChange}
                          color="primary"
                        />
                      }
                      label="Dossier envoyé à l'assurance ?"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="payment_received"
                          checked={Boolean(formData.payment_received)}
                          onChange={handleCheckboxChange}
                          color="primary"
                        />
                      }
                      label="Paiement reçu, facture acquittée ?"
                    />
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleAddCloseModal}
                  >
                    Annuler
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#002654",
                      "&:hover": {
                        backgroundColor: "#ff224d",
                      },
                    }}
                    onClick={handleEdit}
                  >
                    Ajouter des Informations
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>

          {/* rendez vous */}
          <Modal
            open={rendezVousModal}
            onClose={handleRendezVousCloseModal}
            aria-labelledby="rendezvous-modal-title"
            aria-describedby="rendezvous-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 2,
                borderRadius: 4,
              }}
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                onChange={handleChange}
                name="appointment_date"
                value={formData.appointment_date}
                label="Date et Heure de Rendez-vous"
                type="datetime-local"
                id="appointment_date"
                size="small"
              />

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleRendezVousCloseModal}
                >
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#002654",
                    "&:hover": {
                      backgroundColor: "#ff224d",
                    },
                  }}
                  onClick={handleEdit}
                >
                  Fixer un Renedz-vous
                </Button>
              </Box>
            </Box>
          </Modal>
          {/* upload ccA signé model */}
          <Modal
            open={uploadFacturationModel}
            onClose={handleUploadFacturationCloseModel}
            aria-labelledby="rendezvous-modal-title"
            aria-describedby="rendezvous-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 2,
                borderRadius: 4,
              }}
            >
              <UploadDropzone updateForm={(file) => setFile(file)} />

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleUploadFacturationCloseModel}
                >
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#002654",
                    "&:hover": {
                      backgroundColor: "#ff224d",
                    },
                  }}
                  onClick={handleUploadFacturation}
                >
                  Téléverser
                </Button>
              </Box>
            </Box>
          </Modal>

          {/* Cards */}
          {dossier && (
            <Grid container spacing={2}>
              {/* Informations du Dossier Section */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    padding: 2,
                    borderRadius: 2,
                    background: "#fff",
                    color: "#002654",
                    position: "relative",
                    overflow: "hidden",
                    boxShadow: "0px 1px 1px #A5A5A5",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 2,
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: "center",
                      fontWeight: "bold",
                      fontSize: "2rem",
                      color: "#002654",
                      justifyContent: { xs: "center", md: "space-between" },
                    }}
                  >
                    {dossier.name_or_company} {dossier.first_name}
                    {usertype !== "garage" && (
                      <Button
                        variant="contained"
                        endIcon={<AttachFile />}
                        onClick={handleEditeShowModal}
                        sx={{
                          ml: 2,
                          backgroundColor: "rgba(255, 34, 77, 0.8)",
                          borderRadius: "8px",
                          "&:hover": {
                            backgroundColor: "#ff224d",
                          },
                        }}
                      >
                        Modifier
                      </Button>
                    )}
                  </Typography>

                  <Divider />

                  <Grid container spacing={2} sx={{ marginTop: 1 }}>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            mb: 1,
                            color: "#002654",
                            textDecoration: "underline",
                          }}
                        >
                          État du dossier
                        </Typography>

                        {usertype !== "call_center" &&
                          usertype !== "garage" && (
                            <Button
                              variant="contained"
                              onClick={handleAddShowModal}
                              sx={{
                                ml: 2,
                                backgroundColor: "rgba(255, 34, 77, 0.8)",
                                borderRadius: "8px",
                                p: 0.7,
                                fontSize: "0.75rem",
                                "&:hover": {
                                  backgroundColor: "#ff224d",
                                },
                              }}
                            >
                              Modifier l'etat
                            </Button>
                          )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {dossier.status === "" ||
                          steps.indexOf(dossier.status) <=
                            steps.indexOf("Attente de pose") ||
                          !Boolean(dossier.cession_creance) ? (
                            <PendingIcon sx={{ color: "grey" }} />
                          ) : (
                            <CheckIcon color="success" />
                          )}
                          <Typography
                            variant="body2"
                            sx={{
                              color:
                                dossier.status === "" ||
                                steps.indexOf(dossier.status) <=
                                  steps.indexOf("Attente de pose") ||
                                !Boolean(dossier.cession_creance)
                                  ? "#002654"
                                  : "green",
                              fontWeight: "bold",
                            }}
                          >
                            Signé par le client
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {formData.verified_deductible ? (
                            <CheckIcon color="success" />
                          ) : (
                            <PendingIcon sx={{ color: "grey" }} />
                          )}
                          <Typography
                            variant="body2"
                            sx={{
                              color: formData.verified_deductible
                                ? "green"
                                : "#002654",
                              fontWeight: "bold",
                            }}
                          >
                            La vérification est effectuée{" "}
                            {formData.sent_insurance}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {formData.sent_insurance ? (
                            <CheckIcon color="success" />
                          ) : (
                            <PendingIcon sx={{ color: "grey" }} />
                          )}
                          <Typography
                            variant="body2"
                            sx={{
                              color: formData.sent_insurance
                                ? "green"
                                : "#002654",
                              fontWeight: "bold",
                            }}
                          >
                            Dossier envoyé à l'assurance
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {formData.payment_received ? (
                            <CheckIcon color="success" />
                          ) : (
                            <PendingIcon sx={{ color: "grey" }} />
                          )}
                          <Typography
                            variant="body2"
                            sx={{
                              color: formData.payment_received
                                ? "green"
                                : "#002654",
                              fontWeight: "bold",
                            }}
                          >
                            Paiement reçu, facture acquittée ?
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h6"
                        sx={{
                          mb: 1,
                          color: "#002654",
                          textDecoration: "underline",
                        }}
                      >
                        Informations sur le dossier
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        {[
                          {
                            label: "Numéro de Dossier:",
                            value: dossier.dossier_id,
                          },
                          {
                            label: "Dossier Numéro de série:",
                            value: dossier.serial_number,
                          },
                          {
                            label: "Date de création:",
                            value: new Date(
                              dossier.date_created
                            ).toLocaleDateString(),
                          },
                          {
                            label: "Raison de sinistre vitrage:",
                            value: dossier.incident_reason,
                          },
                          {
                            label: "Date de déclaration:",
                            value: new Date(
                              dossier.date_declaration
                            ).toLocaleDateString(),
                          },
                        ].map((item, index) => (
                          <Box key={index} sx={{ display: "flex", mb: 1 }}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#002654",
                                width: "250px",
                              }}
                            >
                              {item.label}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "1rem", color: "#002654" }}
                            >
                              {item.value}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            mb: 1,
                            color: "#002654",
                            textDecoration: "underline",
                          }}
                        >
                          Rendez-vous
                        </Typography>
                        {usertype !== "call_center" && (
                          <Button
                            variant="contained"
                            onClick={handleRendezVousModal}
                            sx={{
                              backgroundColor: "rgba(255, 34, 77, 0.8)",
                              borderRadius: "8px",
                              p: 0.7,
                              fontSize: "0.75rem",
                              "&:hover": {
                                backgroundColor: "#ff224d",
                              },
                            }}
                          >
                            Rendez-vous
                          </Button>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        {[
                          {
                            label: "Date de rendez-vous:",
                            value:
                              dossier.appointment_date &&
                              dossier.appointment_date !== "2020-01-01"
                                ? new Date(
                                    dossier.appointment_date
                                  ).toLocaleDateString()
                                : "Rendez-vous pas encore pris",
                          },
                          {
                            label: "Heure de rendez-vous:",
                            value:
                              dossier.appointment_date &&
                              dossier.appointment_date !== "2020-01-01"
                                ? new Date(
                                    dossier.appointment_date
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                : "Rendez-vous pas encore pris",
                          },
                        ].map((item, index) => (
                          <Box key={index} sx={{ display: "flex", mb: 1 }}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#002654",
                                width: "250px",
                              }}
                            >
                              {item.label}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "1rem", color: "#002654" }}
                            >
                              {item.value}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                    {/* invoices card */}
                    {usertype !== "call_center" && (
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            display:
                              steps.indexOf(dossier.status) >
                              steps.indexOf("Attente de pose")
                                ? "flex"
                                : "none",
                            alignItems: "center",
                            mb: 2,
                            gap: 1,
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              mb: 1,
                              color: "#002654",
                              textDecoration: "underline",
                            }}
                          >
                            Facturation et CCA
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display:
                              steps.indexOf(dossier.status) >
                              steps.indexOf("Attente de pose")
                                ? "flex"
                                : "none",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "40px",
                                mb: 1,
                              }}
                            >
                              <Button
                                variant="contained"
                                size="large"
                                onClick={ViewPdf}
                                sx={{
                                  width: "180px",
                                  p: "8px 0px",
                                  backgroundColor: "#002654",
                                  "&:hover": {
                                    backgroundColor: "#ff224d",
                                  },
                                }}
                                disabled={
                                  steps.indexOf(dossier.status) <=
                                  steps.indexOf("Attente de pose")
                                }
                              >
                                Générer CCA
                                <AutoMode style={{ marginLeft: "8px" }} />
                              </Button>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  border: "1px solid #002654",
                                  borderRadius: "5px",
                                  p: "4px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "1rem", color: "#002654" }}
                                >
                                  Cession-créance-{dossier.dossier_id}.pdf
                                </Typography>
                                <ActionsButton
                                  dataMenu={[
                                    {
                                      Icon: Visibility,
                                      label: "Voir",
                                      action: ViewPdf,
                                    },
                                    {
                                      Icon: Download,
                                      label: "Télécharger",
                                      action: DownloadPdfCCA,
                                    },
                                    {
                                      Icon: Upload,
                                      label: "Téléverser",
                                      action: handleUploadFacturationModal,
                                    },
                                    {
                                      Icon: DownloadDone,
                                      label: " CCA Signé",
                                      action: DownloadPdfCCASigned,
                                      disabled: !Boolean(
                                        dossier.cession_creance
                                      ),
                                    },
                                  ]}
                                />
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "40px",
                                mb: 1,
                              }}
                            >
                              <Button
                                variant="contained"
                                size="large"
                                onClick={(fields.length <= 1 && !fields[0].key) ? handleShowFactureModal : GeneratePdfFacture}
                                sx={{
                                  width: "180px",
                                  p: "8px 0px",
                                  backgroundColor: "#002654",
                                  "&:hover": {
                                    backgroundColor: "#ff224d",
                                  },
                                }}
                                disabled={
                                  steps.indexOf(dossier.status) <=
                                    steps.indexOf("Attente de pose")
                                } 
                              >
                                {
                                  (fields.length <= 1 && !fields[0].key) ? "Éditer Facture" : "Générer Facture"
                                }
                                <AutoMode style={{ marginLeft: "8px" }} />
                              </Button>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  border: "1px solid #002654",
                                  borderRadius: "5px",
                                  p: "4px",
                                  minWidth: "255px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "1rem", color: "#002654" }}
                                >
                                  Facture-{dossier.dossier_id}.pdf
                                </Typography>
                                <ActionsButton
                                  dataMenu={[
                                    {
                                      Icon: Visibility,
                                      label: "Voir",
                                      action: ViewPdfFacture,
                                      disabled: dossier.facture ? false : true,
                                    },
                                    {
                                      Icon: Download,
                                      label: "Telecharger",
                                      action: DownloadPdfFacture,
                                      disabled: dossier.facture ? false : true,
                                    },
                                    {
                                      Icon: Edit,
                                      label: "Modifier",
                                      action: handleShowFactureModal,
                                      disabled: (fields.length <= 1 && !fields[0].key),
                                    },
                                  ]}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>

              <Box sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  {/* Owner Info Card */}
                  <Grid item xs={12} md={6}>
                    <Card
                      sx={{
                        backgroundColor: "#fff",
                        mb: 2,
                        boxShadow: "0px 1px 1px #A5A5A5",
                      }}
                    >
                      <CardHeader
                        avatar={<AccountCircleIcon sx={{ color: "#ff224d" }} />}
                        title="Informations sur le Propriétaire"
                        titleTypographyProps={{
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                          color: "#002654",
                        }}
                      />
                      <Divider />
                      <CardContent>
                        {[
                          { label: "Nom:", value: dossier.name_or_company },
                          { label: "Prénom:", value: dossier.first_name },
                          { label: "Email:", value: dossier.email },
                          { label: "Téléphone:", value: dossier.tel },
                          { label: "Ville:", value: dossier.city },
                          { label: "Adresse:", value: dossier.address },
                          { label: "Code Postal:", value: dossier.postal_code },
                        ].map((item, index) => (
                          <Box key={index} sx={{ display: "flex", mb: 1 }}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#002654",
                                width: "150px",
                              }}
                            >
                              {item.label}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "1rem", color: "#002654" }}
                            >
                              {item.value}
                            </Typography>
                          </Box>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Insurance Info Card */}
                  <Grid item xs={12} md={6}>
                    <Card
                      sx={{
                        backgroundColor: "#fff",
                        mb: 2,
                        boxShadow: "0px 1px 1px #A5A5A5",
                      }}
                    >
                      <CardHeader
                        avatar={<InsuranceIcon sx={{ color: "#ff224d" }} />}
                        title="Informations sur l'Assurance"
                        titleTypographyProps={{
                          fontWeight: "bold",
                          variant: "h6",
                          color: "#002654",
                        }}
                      />
                      <Divider />
                      <CardContent>
                        {[
                          {
                            label: "Client assuré:",
                            value: dossier.insured_client ? "oui" : "",
                          },
                          {
                            label: "Numéro de sinistre:",
                            value: dossier.incident_number,
                          },
                          {
                            label: "Compagnie d'assurance:",
                            value: dossier.insurance_name,
                          },
                          {
                            label: "Numéro de police:",
                            value: dossier.insurance_policy_number,
                          },
                          {
                            label: "Commentaire Assurance:",
                            value: dossier.insurance_comment,
                          },
                          {
                            label: "Franchise Vérifiée ?:",
                            value: dossier.verified_deductible ? "oui" : "",
                          },
                          {
                            label: "Montant de la franchise:",
                            value: (
                              <span
                                style={{
                                  marginLeft: "2px",
                                  fontWeight: "bold",
                                  fontSize: "1rem",
                                  backgroundColor: "#E1E0FF",
                                  padding: "2px 4px",
                                  borderRadius: "4px",
                                  display: "inline-block",
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                {dossier.deductible_amount}$
                              </span>
                            ),
                          },
                        ].map((item, index) => (
                          <Box key={index} sx={{ display: "flex", mb: 1 }}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#002654",
                                width: "250px",
                              }}
                            >
                              {item.label}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "1rem", color: "#002654" }}
                            >
                              {item.value}
                            </Typography>
                          </Box>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Car Info Card */}
                  <Grid item xs={12} md={6}>
                    <Card
                      sx={{
                        backgroundColor: "#fff",
                        mb: 2,
                        boxShadow: "0px 1px 1px #A5A5A5",
                      }}
                    >
                      <CardHeader
                        avatar={<DriveEtaIcon sx={{ color: "#ff224d" }} />}
                        title="Informations sur le Véhicule"
                        titleTypographyProps={{
                          fontWeight: "bold",
                          variant: "h6",
                          color: "#002654",
                        }}
                      />
                      <Divider />
                      <CardContent>
                        {[
                          {
                            label: "Plaque d'immatriculation:",
                            value: dossier.license_plate,
                          },
                          {
                            label: "Modèle de Véhicule :",
                            value: dossier.vehicle_model,
                          },
                          {
                            label: "Kilométrage du véhicule:",
                            value: dossier.vehicle_mileage,
                          },
                          { label: "VIN:", value: dossier.mine_type },
                          {
                            label: "Cadeau:",
                            value: dossier.gift_type,
                          },
                        ].map((item, index) => (
                          <Box key={index} sx={{ display: "flex", mb: 1 }}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#002654",
                                width: "250px",
                              }}
                            >
                              {item.label}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "1rem", color: "#002654" }}
                            >
                              {item.value}
                            </Typography>
                          </Box>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Documents Section Card */}
                  {/* Documents Section Card */}
                  <Grid item xs={12} md={6}>
                    <Card
                      sx={{
                        backgroundColor: "#fff",
                        mb: 2,
                        boxShadow: "0px 1px 1px #A5A5A5",
                        maxHeight: 300,
                        overflowY: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <CardHeader
                          avatar={<DescriptionIcon sx={{ color: "#ff224d" }} />}
                          title="Documents"
                          titleTypographyProps={{
                            fontWeight: "bold",
                            variant: "h6",
                            color: "#002654",
                          }}
                        />
                        {/* {
                        steps.indexOf(dossier.status) >= steps.indexOf("Signature") && (
                          <Button
                            variant="contained"
                            onClick={handleAddShowModal}
                            sx={{
                              backgroundColor: "rgba(255, 34, 77, 0.8)",
                              borderRadius: "8px",
                              p: 0.7,
                              fontSize: "0.75rem",
                              marginRight: "15px",
                              "&:hover": {
                                backgroundColor: "#ff224d",

                              },
                            }}
                          >
                            Information de facturation
                          </Button>
                        )
                      } */}
                        {/* TODO : uncomment when facture generation ready */}
                      </Box>
                      <Divider />
                      <CardContent>
                        {documentData.map((doc, index) => {
                          // Extract the file name from the URL
                          const fileName = doc.url.split("/").pop();
                          return (
                            <Box
                              key={`${doc.url}-${index}`}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 1,
                              }}
                            >
                              <Box sx={{ mr: 1 }}>{fileName}</Box>
                              <Box sx={{ mr: 1, color: "#888" }}>
                                ({doc.type})
                              </Box>
                              <IconButton
                                onClick={() =>
                                  handleDownload(doc.url, doc.fileName)
                                }
                                sx={{ color: "#002654", mr: 1 }}
                                aria-label={`Download ${doc.title}`}
                              >
                                <GetAppIcon />
                              </IconButton>
                            </Box>
                          );
                        })}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>

              {/* Images Section Card */}
              <Grid item xs={12}>
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    mb: 2,
                    boxShadow: "0px 1px 1px #A5A5A5",
                    maxHeight: 400,
                    overflowY: "auto",
                  }}
                >
                  <CardHeader
                    avatar={<ImageIcon sx={{ color: "#ff224d" }} />}
                    title="Images"
                    titleTypographyProps={{
                      fontWeight: "bold",
                      variant: "h6",
                      color: "#002654",
                    }}
                  />
                  <Divider />
                  <CardContent>
                    <ImageList cols={4} rowHeight={200}>
                      {itemData.map((item, index) => {
                        // Extract the file name from the URL
                        const fileName = item.img
                          .split("/")
                          .pop()
                          .split(".")[0];
                        return (
                          <ImageListItem key={`${item.img}-${index}`}>
                            <img
                              srcSet={`${item.img}?w=250&h=250&fit=crop&auto=format&dpr=2 2x`}
                              src={`${item.img}?w=250&h=250&fit=crop&auto=format`}
                              alt={fileName}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                            <ImageListItemBar
                              title={fileName} // Display the file name
                              actionIcon={
                                <IconButton
                                  href={item.img}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                  aria-label={`Open ${fileName} in new tab`}
                                >
                                  <OpenInNewIcon />
                                </IconButton>
                              }
                            />
                          </ImageListItem>
                        );
                      })}
                    </ImageList>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DossierPage;
